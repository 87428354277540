import * as types from './mutation-types'

export default {
    [types.SET_ORDERS] (state, payload) {
        let lotes = [];
        
        if(payload && payload.length > 0) {
            payload.map((item) => {
                const lote = {
                    lote: item.lote,
                    nome_comprador: item.nome_comprador,
                    comprador: item.comprador,
                    fornecedor: item.codigo_fornecedor,
                    statusLot: item.statusLot,
                    descricao: item.descricao,
                    data_processamento: item.data_processamento,
                    acao: item.lote
                }
                
                lotes.push(lote)
            })
        }

        state.orders = lotes
    },

    [types.SET_PERPAGE] (state, payload) {
        state.perPage = payload;
    },

    [types.SET_PENDING_LOT] (state, payload) {
        state.pendingLot = payload;
    },

    [types.SET_ITEMS] (state, payload) {
        state.items = payload;
    },

    [types.SET_PERPAGE_ITEMS] (state, payload) {
        state.perPageItems = payload;
    },

    [types.SET_DATA_DIVISION_QUANTITY] (state, payload) {
        state.dataDivisionQuantity = payload;
    },

    [types.SET_ORDER] (state, payload) {
        state.order = payload;
    },

    [types.SET_QUANTITY_MEMORY] (state, payload) {
        state.quantityMemory = payload;
    },

    [types.SET_ORDER_CREATED] (state, payload) {
        state.orderCreated = payload;
    }

}