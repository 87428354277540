export default  [
    {
        path: '*',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ './views/LoginView.vue')
    }
];