import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  
  if(to.path != '/login') {
      if(to.meta.requireAuth && !store.getters['auth/isLoged'].token) { 
          next('login');
      }
      next();
  } else if(store.getters['auth/isLoged'].token){
      
      next('home');
  }
  next();
});

export default router
