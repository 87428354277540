export default [ 
    { 
        path: '/items/stock/virtual',
        name: 'ItemStockVirtual',
        meta: {
            title: "Estoque Virtual",
            requireAuth: true
        },
        component: () => import(/* webpackChunkName: "ItemStockVirtual" */ './view/ItemStockVirtual.vue')
    }
]