import * as types from './mutation-types'

export const ActionSetOrders = ({commit}, payload) => {
    commit(types.SET_ORDERS, payload)
};

export const ActionSetOrder = ({commit}, payload) => {
    commit(types.SET_ORDER, payload)
};

export const ActionSetPerPage = ({commit}, payload) => {
    commit(types.SET_PERPAGE, payload)
}

export const ActionRemoveItemOrder = ({commit, state}, payload) => {
    
    let orders = state.orders
    const ordersFilter = orders.filter(order => {
        return order.lote != payload
    })

    commit(types.SET_ORDERS, ordersFilter)
}

export const ActionSetPendingLot = ({commit}, payload) => {

    commit(types.SET_PENDING_LOT, payload)
}

export const ActionSetItems = ({commit}, payload) => {

    commit(types.SET_ITEMS, payload)
}

export const ActionSetPerPageItems = ({commit}, payload) => {

    commit(types.SET_PERPAGE_ITEMS, payload)
}

export const ActionSetDataDivisionQuantity = ({commit}, payload) => {
    commit(types.SET_DATA_DIVISION_QUANTITY, payload);
}

export const ActionSetQuantityMemory = ({commit, state}, payload) => {
    let items = state.quantityMemory
    items.push(payload)
    commit(types.SET_QUANTITY_MEMORY, items);
}

export const ActionReSetQuantityMemory = ({commit}, payload) => {
    commit(types.SET_QUANTITY_MEMORY, payload);
}

export const ActionReSetOrderCreated = ({commit}, payload) => {
    commit(types.SET_ORDER_CREATED, payload);
}