import {store as auth} from '../modules/auth'
import {store as alert} from '../global/alert'
import {store as order} from '../modules/order'
import {store as vendor} from '../modules/vendors'
import {store as emporium} from '../modules/emporium'
import {store as buyer} from '../modules/buyers'
import {store as stock} from '../modules/stock/index'

export default {
    auth,
    alert,
    order,
    vendor,
    emporium,
    buyer,
    stock
}