import * as types from './mutation-types'


export const ActionSetVendors = ({commit}, payload) => {
    commit(types.SET_VENDOR, payload);
}

export const ActionSetVendorName = ({commit}, payload) => {
    commit(types.SET_VENDOR_NAME, payload);
}
export const ActionSetVendorSelected = ({commit}, payload) => {
    commit(types.SET_VENDOR_SELECTED, payload);
}