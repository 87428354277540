import mutations from "./mutations"
import states from './states'
import * as getters from './getters'
import * as actions from './actions'

export default {
    mutations,
    getters,
    actions,
    states,
    namespaced: true
}