import * as types from './mutation-types'

export default {
    [types.SET_VENDOR] (state, payload) {
        state.vendorList = payload
    },

    [types.SET_VENDOR_NAME] (state, payload) {
        state.vendorName = payload
    },

    [types.SET_VENDOR_SELECTED] (state, payload) {
        state.vendorSelected = payload
    }
}