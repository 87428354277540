import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import ConversionTime from './plugins/ConversionTime'
import { currency, number} from './plugins/NumberFormat'

import './assets/scss/app.scss'

import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTruckFast } from '@fortawesome/free-solid-svg-icons'
import { faCubes } from '@fortawesome/free-solid-svg-icons'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
library.add(faTruckFast, faCubes, faCartShopping, faPowerOff)


Vue.component('font-awesome-icon', FontAwesomeIcon);


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ConversionTime)
Vue.use(currency)
Vue.use(number)

export const enventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
