import * as types from './mutation-types'

export default {
    [types.SET_BUYER] (state, payload) {
        state.buyerList = payload
    },
    [types.SET_NAME] (state, payload) {
        state.searchBuyerName = payload
    },
    [types.SET_NUMBER] (state, payload) {
        state.searchBuyerNumber = payload
    },
    [types.SET_TEXT] (state, payload) {
        state.searchBuyerText = payload
    },

    [types.SET_DISABLED_SEARCH] (state, payload) {
        state.disableBuyerSearch = payload;
    }
}