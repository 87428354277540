export default {
    install (Vue) {
      Vue.directive('data-br', {
        bind: (el, binding) => {
          if(binding.value) {
            let typeReturn = binding.arg || 'date'
  
            let dateTime = binding.value.split(' ')
            let dateResponse = dateTime[0].split('-').reverse().join('/')
            typeReturn = (typeReturn != 'date' && dateTime.length > 1) ? typeReturn : 'date'
            if(typeReturn == 'date') {
                el.innerHTML = dateResponse    
                return true
            }
            el.innerHTML = `${dateResponse} ${dateTime[1]}`
            return true;
          }
          el.innerHTML = '';
        }
      }),


        Vue.mixin({
            methods: {
              dataBr(data, typeReturn) {
                if(data) {
                  let dateTime = data.split(' ')
                  let dateResponse = dateTime[0].split('-').reverse().join('/')
                  typeReturn = (typeReturn != 'date' && dateTime.length > 1) ? typeReturn : 'date'
                  
                  if(typeReturn == 'date') {
                    return dateResponse    
                  }
                  return `${dateResponse} ${dateTime[1]}`
                }
                return null
              }
            },
        })   
    }
  }