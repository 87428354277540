export default {
    data: {
        orders : [],
        order: null,
        perPage: 0,
        pendingLot: null,
        items: [],
        perPageItems: 0,
        dataDivisionQuantity: {},
        quantityMemory: [],
        orderCreated: false
    }
} 