export const currency = {
    install(Vue) {
      Vue.directive("currency-br", {
        bind: (el, binding) => {
          const currency = new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(binding.value);
          el.innerHTML = currency;
        }
      });

      Vue.mixin({
            methods: {
                currencyBr(number) {
                    return new Intl.NumberFormat("pt-br", {
                        style: "currency",
                        currency: "BRL"
                    }).format(number);
                },

                
                numberBr(number) {
                  number = !number ? 0 : number;
                    return new Intl.NumberFormat("pt-br", {minimumFractionDigits: 2}).format(parseFloat(number).toFixed(2));
                }
            }
        })
    }
  };


  export const number = {
    install(Vue) {
      Vue.directive("number-br", {
        bind: (el, binding) => {
          let number = (!binding.value ? 0 : binding.value); 
          number = new Intl.NumberFormat("pt-br", {minimumFractionDigits: 2}).format(parseFloat(number).toFixed(2));
          el.innerHTML = number;
        }
      });
    }
  };