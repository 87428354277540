export default [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            requireAuth: true
        },
        component: () => import(/* webpackChunkName: "Home" */ './views/HomeOrders.vue')
    },
    {
        path: '/ordens',
        name: 'OrdensCompras',
        meta: {
            title: "Ordens de Compras",
            requireAuth: true
        },
        component: () => import(/* webpackChunkName: "OrdensCompras" */ './views/HomeView.vue')
    },
    {
        path: '/order/item/:vendor/:lot',
        name: 'OrderItem',
        meta: {
            title: "Order",
            requireAuth: true
        },
        component: () => import(/* webpackChunkName: "OrderItem" */ './views/OrderItems.vue')
    },
    {
        path: '/order/item/:vendor/:lot',
        name: 'OrderItemMobile',
        meta: {
            title: "Order",
            requireAuth: true
        },
        component: () => import(/* webpackChunkName: "OrderItem" */ './views/mobile/OrderItemsMobile.vue')
    },
];