import * as types from './mutation-types'


export const ActionSetBuyers = ({commit}, payload) => {
    
    commit(types.SET_BUYER, payload);
}

export const ActionSetBuyerFound = ({commit}, payload) => {
    
    commit(types.SET_NAME, payload.name);
    commit(types.SET_NUMBER, payload.number);
}

export const ActionSetText = ({commit}, payload) => {
    commit(types.SET_TEXT, payload);
}

export const ActionSetBuyerDisableSearch = ({commit}, payload) => {
    commit(types.SET_DISABLED_SEARCH, payload)
}