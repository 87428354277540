import * as types from './mutation-types'

export default {

    [types.SET_EMPORIUMS] (state, payload) {
        state.emporiums = payload;
    },

    [types.SET_CDS] (state, payload) {
        state.cds = payload;
    }

}