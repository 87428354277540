import mutations from './mutations'
import states from './states'
import * as actions from './actions'


export default {
    mutations,
    states,
    actions,
    namespaced: true
}